export const EXTERNAL_URLS = {
  support: 'https://support.curology.com',
  careers: 'https://jobs.lever.co/curology',
  instagram: 'https://www.instagram.com/curology/',
  facebook: 'https://www.facebook.com/Curology/',
  linkedin: 'https://www.linkedin.com/company/curology/',
  snapchat: 'https://www.snapchat.com/add/curology',
  twitter: 'https://twitter.com/CurologyUSA',
  youtube: 'https://www.youtube.com/Curology',
  pinterest: 'https://www.pinterest.com/curology/',
  accessProgram: 'https://form.typeform.com/to/OiIgmf',
  copyrightGov: 'http://www.copyright.gov/',
  summersaltPrivacy: 'https://www.summersalt.com/pages/privacy-policy',
  googlePrivacy: 'https://policies.google.com/privacy',
  googleAnalyticsOptout: 'https://tools.google.com/dlpage/gaoptout',
  hudsonAndBleeckerPrivacy:
    'https://www.hudsonandbleecker.com/pages/the-legal-stuff',
  aboutAdsConsumer: 'http://www.aboutads.info/consumers',
  consumerArbitrationSite: 'https://www.adr.org',
  consumerArbitrationRules:
    'https://www.adr.org/aaa/ShowProperty?nodeId=/UCM/ADRSTAGE2021425&revision=latestreleased',
  wikipediaComparisonContentControlSW:
    'https://en.wikipedia.org/wiki/Comparison_of_content-control_software_and_providers',
  contactForm:
    'https://curology-curology.kustomer.help/contact/curology-contact-us-SJ_vMCv3O',

  // Support Articles
  pricing: 'https://support.curology.com/en_us/pricing-and-products-BJOLQnd2O',
  returnPolicy: 'https://support.curology.com/en_us/return-policy-rJTUQnd2d',
  learnMoreAccessProgram:
    'https://support.curology.com/en_us/curology-access-program-HJcu73unu',
  acneBodyWashArticle:
    "https://support.curology.com/en_us/curology's-acne-body-wash-HyesQhund",
  stateByStateAvailabilityArticle:
    'https://support.curology.com/en_us/state-by-state-availability-rJDDQ2d3_',

  // Studies
  otcProductsConsumerReport:
    'https://www.consumerreports.org/cro/2011/11/review-of-acne-treatments/index.htm',
  combinationEfficacy: 'https://www.ncbi.nlm.nih.gov/pubmed/19810365',

  // References
  gptwReference: 'https://www.greatplacetowork.com/certified-company/7021618',
  forbesReference: 'https://www.forbes.com/companies/curology',
  incReference: 'https://www.inc.com/profile/curology',
  fortuneReference: 'https://fortune.com/best-medium-workplaces/2020/curology/',
};
