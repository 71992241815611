import React, { ReactNode } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { PAGE_URLS } from 'consts/pageUrls';
import ECOMMERCE_URLS from 'consts/eCommerceUrls';
import APP_URLS from 'consts/appUrls';
import {
  PRODUCT_DISPLAY_NAMES,
  PRODUCT_CATEGORIES,
  PRODUCT_LINKS,
} from 'consts/products';
import { TREATMENT_CATEGORY_IDS } from 'consts/treatmentCategories';

interface LinkType {
  label: string;
  htmlLabel?: ReactNode;
  href: string;
}

export interface TaggedLinkType extends LinkType {
  tags?: string[];
}

interface ProductCategoryType {
  category: valueof<typeof PRODUCT_CATEGORIES>;
  linkType: valueof<typeof PRODUCT_LINKS>;
  links: TaggedLinkType[];
}

type Skincare101Links = {
  category: string;
  links: LinkType[];
}[];

type Skincare101FeauturedArticles = {
  media: JSX.Element;
  title: string;
  published: string;
  timeToRead: string;
  href: string;
}[];

export type Service = {
  title: string;
  subtitle?: string;
  href: string;
  treatmentCategoryId: number;
  testID?: string;
};

type SelectServiceType = {
  title: string;
  services: Service[];
};

export const NAV_GROUP_PRODUCTS = 'productCategories';
export const NAV_GROUP_SKINCARE = 'skincare101';
export const NAV_GROUP_SERVICES = 'serviceTypes';

export type NavGroupType =
  | typeof NAV_GROUP_PRODUCTS
  | typeof NAV_GROUP_SKINCARE
  | typeof NAV_GROUP_SERVICES;

export const NAV_GROUP_URL_MAP: Record<NavGroupType, string> = {
  [NAV_GROUP_PRODUCTS]: 'products',
  [NAV_GROUP_SKINCARE]: 'blog',
  [NAV_GROUP_SERVICES]: '',
};

const CHIA_PRODUCT_LINK = {
  label: 'Hair Formula for hair loss',
  htmlLabel: (
    <React.Fragment>
      Hair Formula<sup>Rx</sup> for hair loss
    </React.Fragment>
  ),
  href: PAGE_URLS.products.hairFormula,
  tags: ['Rx', 'New!'],
};

export const NAV_PRODUCT_CATEGORIES_CONTENT: ProductCategoryType[] = [
  {
    category: PRODUCT_CATEGORIES.prescription,
    linkType: PRODUCT_LINKS.internal,
    links: [
      {
        label: `${PRODUCT_DISPLAY_NAMES.customFormula} for acne`,
        href: PAGE_URLS.products.customFormulaAcne,
        tags: ['Rx'],
      },
      {
        label: `${PRODUCT_DISPLAY_NAMES.futureProof} for anti-aging`,
        href: PAGE_URLS.products.futureProofAntiAging,
        tags: ['Rx'],
      },
    ],
  },

  {
    category: PRODUCT_CATEGORIES.overTheCounter,
    linkType: PRODUCT_LINKS.eCommerce,
    links: [
      {
        label: PRODUCT_DISPLAY_NAMES.cleanser,
        href: ECOMMERCE_URLS.cleanser,
      },
      {
        label: PRODUCT_DISPLAY_NAMES.emergencySpotPatches,
        href: ECOMMERCE_URLS.emergencySpotPatch,
      },
      {
        label: PRODUCT_DISPLAY_NAMES.emergencySpotPatchClusters,
        href: ECOMMERCE_URLS.emergencySpotPatchClusters,
        tags: ['New!'],
      },
      {
        label: PRODUCT_DISPLAY_NAMES.acneCleanser,
        href: ECOMMERCE_URLS.acneCleanser,
      },
      {
        label: PRODUCT_DISPLAY_NAMES.moisturizer,
        href: ECOMMERCE_URLS.moisturizer,
      },
      {
        label: PRODUCT_DISPLAY_NAMES.richMoisturizer,
        href: ECOMMERCE_URLS.richMoisturizer,
      },
      {
        label: PRODUCT_DISPLAY_NAMES.sunscreen,
        href: ECOMMERCE_URLS.sunscreen,
      },
      {
        label: PRODUCT_DISPLAY_NAMES.acneBodyWash,
        href: ECOMMERCE_URLS.acneBodyWash,
      },
      {
        label: PRODUCT_DISPLAY_NAMES.micellarMakeupRemover,
        href: ECOMMERCE_URLS.micellarMakeupRemover,
      },
      {
        label: PRODUCT_DISPLAY_NAMES.lipBalm,
        href: ECOMMERCE_URLS.lipBalm,
      },
      {
        label: PRODUCT_DISPLAY_NAMES.barrierBalm,
        href: ECOMMERCE_URLS.barrierBalm,
        tags: ['New!'],
      },
      {
        label: PRODUCT_DISPLAY_NAMES.liquidPoreExfoliant,
        href: ECOMMERCE_URLS.liquidPoreExfoliant,
        tags: ['New!'],
      },
      {
        label: PRODUCT_DISPLAY_NAMES.darkSpotSerum,
        href: ECOMMERCE_URLS.darkSpotSerum,
        tags: ['New!'],
      },
      {
        label: PRODUCT_DISPLAY_NAMES.hydrationSerum,
        href: ECOMMERCE_URLS.hydrationSerum,
        tags: ['New!'],
      },
    ],
  },
  {
    category: PRODUCT_CATEGORIES.sets,
    linkType: PRODUCT_LINKS.eCommerce,
    links: [
      {
        label: PRODUCT_DISPLAY_NAMES.starterSet,
        href: ECOMMERCE_URLS.starterSet,
      },
    ],
  },
];

const CHIA_NAV = {
  ...NAV_PRODUCT_CATEGORIES_CONTENT[0],
  links: [...NAV_PRODUCT_CATEGORIES_CONTENT[0].links, CHIA_PRODUCT_LINK],
};

export const NAV_CHIA_PRODUCT_CATEGORIES_CONTENT: ProductCategoryType[] = [
  CHIA_NAV,
  NAV_PRODUCT_CATEGORIES_CONTENT[1],
  NAV_PRODUCT_CATEGORIES_CONTENT[2],
];

export const NAV_SKINCARE_101_LINK_CONTENT: Skincare101Links = [
  {
    category: 'Our favorite topics',
    links: [
      {
        label: 'Skin concerns',
        href: PAGE_URLS.blog.category.skinConcerns,
      },
      {
        label: 'Skin treatments',
        href: PAGE_URLS.blog.category.skinTreatment,
      },
      {
        label: 'Skincare products',
        href: PAGE_URLS.blog.category.skinProducts,
      },
      {
        label: 'Ingredients',
        href: PAGE_URLS.blog.category.ingredients,
      },
    ],
  },
  {
    category: 'More content',
    links: [
      {
        label: 'Curology',
        href: PAGE_URLS.blog.category.curology,
      },
      {
        label: 'Mind & body',
        href: PAGE_URLS.blog.category.mindAndBody,
      },
      {
        label: 'Video',
        href: PAGE_URLS.blog.category.video,
      },
      {
        label: 'All stories',
        href: PAGE_URLS.blog.home,
      },
    ],
  },
];

export const NAV_SERVICES_CONTENT: SelectServiceType = {
  title: 'What would you like to treat?',
  services: [
    {
      title: 'Hair',
      subtitle: 'Fight hair loss and boost hair growth',
      href: APP_URLS.signUpHair,
      treatmentCategoryId: TREATMENT_CATEGORY_IDS.hair,
    },
    {
      title: 'Skin',
      subtitle: 'Tackle acne or get ahead on preventative aging',
      href: APP_URLS.signUpSkin,
      treatmentCategoryId: TREATMENT_CATEGORY_IDS.skin,
      testID: 'chia',
    },
  ],
};

export const NAV_SKINCARE_101_FEATURED_ARTICLES_CONTENT: Skincare101FeauturedArticles =
  [
    {
      media: (
        <StaticImage
          src="../images/acneFaceMapping@2x.jpg"
          alt="woman pointing at skin"
          width={272}
        />
      ),
      title: 'Acne face mapping: What your skin is trying to tell you',
      published: 'Published: 10/21/2021',
      timeToRead: '6 min read',
      href: PAGE_URLS.blog.acneFaceMapping,
    },
    {
      media: (
        <StaticImage
          src="../images/quiz@2x.jpg"
          alt="woman and man"
          width={272}
        />
      ),
      title: 'Quiz: Your personality type, according to your skincare routine',
      published: 'Published: 1/05/2022',
      timeToRead: '6 min read',
      href: PAGE_URLS.blog.quiz,
    },
  ];
