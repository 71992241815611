export const COMPANY_INFO = {
  sanDiego: {
    title: 'Office in San Diego',
    company: 'Curology',
    addressLine1: '6195 Lusk Blvd, Suite 250',
    addressLine2: 'San Diego, CA 92121',
  },
  stLouis: {
    title: 'PARTNER PHARMACY IN ST. LOUIS',
    company: 'Skin Specialty Solutions, Inc.',
    addressLine1: '2058 Fenton Logistics Park Blvd',
    addressLine2: 'Fenton, MO 63026',
    phone: '(877) 273-1777',
  },
  pittsburgh: {
    title: 'PARTNER PHARMACY IN PITTSBURGH',
    company: 'Personalized Skin Solutions, Inc.',
    addressLine1: '155 Solar Drive',
    addressLine2: 'Imperial, PA 15126',
    phone: '(888) 210-4777',
  },
  emails: {
    hello: 'hello@curology.com',
    support: 'support@curology.com',
    privacy: 'privacy@curology.com',
    legal: 'legal@curology.com',
  },
  sms: '(415) 200-2060',
} as const;
