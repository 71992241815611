import { useLocation } from '@gatsbyjs/reach-router';
import { GTM_DATA_LAYER_SCRIPT } from 'great-gatsby/utils';
import { Helmet } from 'react-helmet';

import { useCanonicalHistory } from 'components/global/CanonicalHistoryProvider';
import type { LandingVariantsType } from 'consts/landingVariants';
import { OSANO_SCRIPT_URL } from 'consts/scripts';
import coreImageSrc from 'images/seo/core-og-image.jpg';

import { SCHEMA_ORG_SCRIPT } from './consts/schemaOrgScript';
import { REMOVE_AMP_DEVICE_ID_QUERY_PARAM_SCRIPT } from './consts/scripts';
import { getMetadata } from './utils/getMetadata';

export interface SEOPropsType {
  title?: string;
  ogTitle?: string;
  description?: string;
  ogDescription?: string;
  ogImage?: string;
  isIndexableOverride?: boolean;
  variant?: LandingVariantsType;
}

const defaultTitle = 'Curology - Custom skincare delivered to your door';
const defaultDescription =
  'For healthy and beautiful skin, get skincare customized for you from experts at Curology.';

function isCanonicalTagExcluded(path: string) {
  const pathsToExclude: Array<RegExp> = [
    /\/free-trial\/[\S]+/,
    /\/promotions\/[\S]+/,
  ];

  return pathsToExclude.some((exclude) => {
    return path.match(exclude);
  });
}

function isNoindexTagIncluded(path: string) {
  const pathsToInclude: Array<RegExp> = [
    /\/free-trial\/[\S]+/,
    /\/promotions\/[\S]+/,
  ];

  return pathsToInclude.some((include) => {
    return path.match(include);
  });
}

export const SEO = ({
  title = defaultTitle,
  ogTitle = title,
  description = defaultDescription,
  ogDescription = description,
  ogImage = coreImageSrc,
  isIndexableOverride = false,
}: SEOPropsType) => {
  const { pathname } = useLocation();
  const { currentCanonicalUrl } = useCanonicalHistory();

  const meta = getMetadata({
    ogTitle,
    ogDescription,
    ogImage,
    ogUrl: currentCanonicalUrl,
  });

  return (
    <Helmet
      htmlAttributes={{ lang: 'en' }}
      title={title}
      meta={meta.concat([
        {
          name: 'description',
          content: description,
        },
        {
          name: 'robots',
          content: `${
            isNoindexTagIncluded(pathname) && !isIndexableOverride
              ? 'noindex, follow'
              : 'index'
          }`,
        },
      ])}
    >
      <script src={OSANO_SCRIPT_URL}></script>
      <script type="text/javascript">
        {REMOVE_AMP_DEVICE_ID_QUERY_PARAM_SCRIPT}
      </script>
      {/*
       * Helmet does not allow nested React Components and
       * scripts must be passed as strings.
       */}
      {(!isCanonicalTagExcluded(pathname) || isIndexableOverride) && (
        <link rel="canonical" href={currentCanonicalUrl} />
      )}
      <link rel="preconnect" href="https://assets.curology.com" />
      <script id="gtmDataLayer" type="text/javascript">
        {GTM_DATA_LAYER_SCRIPT}
      </script>
      <script type="application/ld+json">{SCHEMA_ORG_SCRIPT}</script>
    </Helmet>
  );
};
