import { Typography } from '@curology/ui-components-web/react';
import { Link } from 'gatsby';
import { ChevronIcon } from 'radiance-ui/lib/icons';
import React, { useState } from 'react';

import APP_URLS from 'consts/appUrls';
import { CTA_FIND_YOUR_TREATMENT } from 'consts/copy';
import { EVENTS } from 'consts/metricsTrackingEvents';
import { PAGE_URLS } from 'consts/pageUrls';
import { SYNTHETIC_TEST_IDS } from 'consts/syntheticTestIds';
import { trackEvent, trackSignupCTAClick } from 'utils/metricsTracking';

import { useLocation } from '@gatsbyjs/reach-router';
import { ModalDesktop, NavLink } from '..';
import {
  NAV_GROUP_PRODUCTS,
  NAV_GROUP_SKINCARE,
  NAV_GROUP_SERVICES,
  type NavGroupType,
} from '../../content';
import {
  LinksContainer,
  PrimaryLinkContainer,
  chevronIconStyles,
  mainLinkStyles,
  styleLink,
} from './NavLinksDesktop.style';
import { isNavGroupActive } from './isNavGroupActive';
import { getFormattedLink } from 'utils/getFormattedLink';

export const NavLinksDesktop = ({
  bannerHeight,
  navType,
  setIsModalOpen,
  isModalOpen,
  isMarketingSafe = false,
  minimalNavigation = false,
  ctaTextOverride,
  isHidingProductCtas = false,
  ctaLinkOverride,
}: {
  bannerHeight: number;
  navType: 'primary' | 'secondary';
  setIsModalOpen: (arg: boolean) => void;
  isModalOpen: boolean;
  isMarketingSafe?: boolean;
  minimalNavigation: boolean;
  ctaTextOverride?: string;
  isHidingProductCtas?: boolean;
  ctaLinkOverride?: string;
}) => {
  const [activeNavGroup, setActiveNavGroup] = useState<NavGroupType>();
  const { pathname } = useLocation();

  const isProductsNavGroupActive = isNavGroupActive(
    pathname,
    NAV_GROUP_PRODUCTS,
  );
  const isSkincare101NavGroupActive = isNavGroupActive(
    pathname,
    NAV_GROUP_SKINCARE,
  );

  const moduleName = 'nav';

  const isOnHairPage =
    pathname.includes('hair-loss') || pathname.includes('hair-growth');

  // TODO [GE-3580]: Temporarily disables the "Find your treatment" modal in favor of just being directed to the skin SUF on click. Keeping the copy "Find your treatment" for the CTA though.
  const showFindYourTreatment = false; // ctaTextOverride !== CTA_START_TRIAL_RETAIL;

  const trackLoginClick = () => {
    trackEvent(EVENTS.loginClicked, {
      path: window.location.pathname,
      module: moduleName,
    });
  };

  const trackCTAClick = () => {
    trackSignupCTAClick(moduleName);

    if (showFindYourTreatment || isOnHairPage) {
      openModal(NAV_GROUP_SERVICES);
    }
  };

  const toggleModal = (navGroup: NavGroupType) => {
    if (!(isModalOpen && navGroup !== activeNavGroup)) {
      setIsModalOpen(!isModalOpen);
    }
    setActiveNavGroup(navGroup);
  };

  const openModal = (navGroup: NavGroupType) => {
    if (!isModalOpen) {
      setIsModalOpen(true);
    }
    setActiveNavGroup(navGroup);
  };

  const closeModal = () => isModalOpen && setIsModalOpen(false);

  const showNavigation = !(isMarketingSafe || minimalNavigation);
  let ctaLink = APP_URLS.welcome;

  if (ctaLinkOverride) {
    ctaLink = getFormattedLink(ctaLinkOverride);
  } else if (showFindYourTreatment || isOnHairPage) {
    ctaLink = '';
  }

  return (
    <React.Fragment>
      <LinksContainer isHidingProductCtas={isHidingProductCtas}>
        {showNavigation && (
          <React.Fragment>
            {!isHidingProductCtas && (
              <NavLink
                onClick={() => toggleModal(NAV_GROUP_PRODUCTS)}
                onMouseEnter={() => openModal(NAV_GROUP_PRODUCTS)}
                aria-expanded={
                  activeNavGroup === NAV_GROUP_PRODUCTS && isModalOpen
                }
                aria-label="Shop products"
                aria-haspopup="true"
                aria-current={isProductsNavGroupActive ? 'page' : undefined}
                css={mainLinkStyles(navType)}
              >
                <PrimaryLinkContainer navType={navType}>
                  <Typography size="subhead">Shop products</Typography>
                  <ChevronIcon
                    css={chevronIconStyles(
                      navType,
                      isModalOpen && activeNavGroup === NAV_GROUP_PRODUCTS,
                    )}
                  />
                </PrimaryLinkContainer>
              </NavLink>
            )}
            {activeNavGroup === NAV_GROUP_PRODUCTS && (
              <ModalDesktop
                isOpen={isModalOpen}
                bannerHeight={bannerHeight}
                setIsModalOpen={setIsModalOpen}
                activeNavGroup={activeNavGroup}
              />
            )}
            <Link css={mainLinkStyles(navType)} to={PAGE_URLS.whyCurology}>
              <PrimaryLinkContainer navType={navType} onMouseEnter={closeModal}>
                <Typography size="subhead">Why Curology</Typography>
              </PrimaryLinkContainer>
            </Link>
            <Link css={mainLinkStyles(navType)} to={PAGE_URLS.reviews}>
              <PrimaryLinkContainer navType={navType} onMouseEnter={closeModal}>
                <Typography size="subhead">Reviews</Typography>
              </PrimaryLinkContainer>
            </Link>
            <NavLink
              onClick={() => toggleModal(NAV_GROUP_SKINCARE)}
              onMouseEnter={() => openModal(NAV_GROUP_SKINCARE)}
              aria-expanded={
                activeNavGroup === NAV_GROUP_SKINCARE && isModalOpen
              }
              aria-label="Skincare 101"
              aria-haspopup="true"
              aria-current={isSkincare101NavGroupActive ? 'page' : undefined}
              css={mainLinkStyles(navType)}
            >
              <PrimaryLinkContainer navType={navType}>
                <Typography size="subhead">Skincare 101</Typography>
                <ChevronIcon
                  css={chevronIconStyles(
                    navType,
                    isModalOpen && activeNavGroup === NAV_GROUP_SKINCARE,
                  )}
                />
              </PrimaryLinkContainer>
            </NavLink>
            {activeNavGroup === NAV_GROUP_SKINCARE && (
              <ModalDesktop
                isOpen={isModalOpen}
                bannerHeight={bannerHeight}
                setIsModalOpen={setIsModalOpen}
                activeNavGroup={activeNavGroup}
              />
            )}

            <NavLink
              css={mainLinkStyles(navType)}
              href={APP_URLS.login}
              isExternal
              trackClick={trackLoginClick}
            >
              <PrimaryLinkContainer navType={navType}>
                <Typography size="subhead">Log In</Typography>
              </PrimaryLinkContainer>
            </NavLink>
          </React.Fragment>
        )}
        <NavLink
          data-synthetics-id={SYNTHETIC_TEST_IDS.headerCta}
          css={styleLink(navType, 'signup-dark')}
          href={ctaLink}
          isExternal
          trackClick={trackCTAClick}
        >
          <Typography size="subhead">
            {/* TODO [GE-3580]: Temporarily disables the "Find your treatment" modal in favor of just being directed to the skin SUF on click. Keeping the copy "Find your treatment" for the CTA though. */}
            {ctaTextOverride || CTA_FIND_YOUR_TREATMENT}
            {/* {ctaTextOverride ||
              (showFindYourTreatment || isOnHairPage
                ? CTA_FIND_YOUR_TREATMENT
                : CTA_UNLOCK_YOUR_OFFER)} */}
          </Typography>
        </NavLink>
        {activeNavGroup === NAV_GROUP_SERVICES && (
          <ModalDesktop
            isOpen={isModalOpen}
            bannerHeight={bannerHeight}
            setIsModalOpen={setIsModalOpen}
            activeNavGroup={activeNavGroup}
          />
        )}
      </LinksContainer>
    </React.Fragment>
  );
};
