/*
 * This layout is automatically applied to every page
 * by 'gatsby-plugin-layout'. ErrorBoundary does not
 * catch errors consistently without plugin.
 *
 * https://www.gatsbyjs.org/packages/gatsby-plugin-layout/
 */
import { Global, ThemeProvider } from '@emotion/react';
import { BrandStyles, ResetStyles } from 'radiance-ui';
import { primaryTheme } from 'radiance-ui/lib/constants';
import React, { useEffect, useState } from 'react';

import { Analytics } from 'components/global/Analytics';
import { CanonicalHistoryProvider } from 'components/global/CanonicalHistoryProvider';
import { ErrorBoundary } from 'components/global/errors/ErrorBoundary';
import { GlobalSchemaErrorIndicator } from 'components/global/errors/GlobalSchemaErrorIndicator';
import fonts from 'styles/fonts';
import GlobalTypography from 'styles/typography/global-typography';
import cssVariables from 'styles/variables';
import { ECommerceProvider } from 'utils/eCommerce/ECommerceProvider';
import { initLd, useFlag } from 'utils/featureFlagsAndMetrics';

import { COOKIE_KEYS } from 'consts/cookieKeys';
import cookie from 'utils/cookie';
import { LayoutContainer } from './style';

const Layout = ({ children }: { children: React.ReactNode }) => {
  const [isClientReady, setIsClientReady] = useState(false);
  const amplitudeBrowserSdk = useFlag<boolean>('amplitude-browser-sdk');

  useEffect(() => {
    initLd();
    window.addEventListener('amplitude_init', () => {
      initLd();
    });
    setIsClientReady(true);
  }, []);

  useEffect(() => {
    if (amplitudeBrowserSdk === undefined) {
      return;
    }

    cookie.set(
      COOKIE_KEYS.amplitudeBrowserSdk,
      amplitudeBrowserSdk ? 'true' : 'false',
    );
  }, [amplitudeBrowserSdk]);

  return (
    <ThemeProvider theme={primaryTheme}>
      <CanonicalHistoryProvider>
        <Analytics />
        <ECommerceProvider>
          <LayoutContainer>
            {isClientReady && <div id="client_ready" />}
            <Global styles={[fonts, cssVariables]} />
            <BrandStyles />
            <ResetStyles />
            <GlobalTypography />
            <ErrorBoundary>{children}</ErrorBoundary>
            {process.env.CONTENTFUL_HOST === 'preview.contentful.com' && (
              <GlobalSchemaErrorIndicator />
            )}
          </LayoutContainer>
        </ECommerceProvider>
      </CanonicalHistoryProvider>
    </ThemeProvider>
  );
};

export default Layout;
