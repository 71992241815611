import React from 'react';
import { IconName } from '@curology/ui-components-web/react';
import { NavBannerProps } from '..';
import { LOCAL_COLORS } from 'styles/constants';

export const POP_REDESIGN_BANNER_CONTENT: NavBannerProps = {
  backgroundColor: LOCAL_COLORS.surfaceOrange,
  textColor: LOCAL_COLORS.surfaceDarkBold,
  iconName: IconName.Gift,
  text: (
    <React.Fragment>
      <strong>First skincare box JUST $15!</strong>
    </React.Fragment>
  ),
};

export const POP_REDESIGN_SEPTEMBER_PROMO_BANNER_CONTENT: NavBannerProps = {
  ...POP_REDESIGN_BANNER_CONTENT,
  text: (
    <React.Fragment>
      <strong>First skincare box JUST $15</strong>
    </React.Fragment>
  ),
};

export const POP_REDESIGN_JULY_PROMO_BANNER_CONTENT: NavBannerProps = {
  ...POP_REDESIGN_BANNER_CONTENT,
  text: (
    <React.Fragment>
      Limited time offer - <strong>first skincare box JUST $9.95!</strong>
    </React.Fragment>
  ),
};
