import React, { useEffect } from 'react';
import { Transition } from 'react-transition-group';
import { keyPressMatch } from '@curology-engineering/great-gatsby/utils';

import { SlideDownModal } from 'components/global/modal';
import { KEYCODES } from 'consts/keyCodes';

import { ContentContainer, ModalContainer } from './ModalDesktop.style';
import {
  ProductsModalDesktop,
  Skincare101ModalDesktop,
  ServicesModalDesktop,
} from './components';
import {
  NAV_GROUP_PRODUCTS,
  NAV_GROUP_SKINCARE,
  NAV_GROUP_SERVICES,
  type NavGroupType,
} from '../../content';

export const ModalDesktop = ({
  isOpen,
  bannerHeight,
  setIsModalOpen,
  activeNavGroup = NAV_GROUP_PRODUCTS,
}: {
  isOpen: boolean;
  bannerHeight: number;
  setIsModalOpen: (arg: boolean) => void;
  activeNavGroup?: NavGroupType;
}) => {
  const onClose = () => {
    setIsModalOpen(false);
  };

  const handleEscapeKey = (event: KeyboardEvent) => {
    if (keyPressMatch(event, KEYCODES.escape)) {
      onClose();
    }
  };

  useEffect(() => {
    document.body.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.body.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  const transitionDuration = 250;

  return (
    <Transition in={isOpen} timeout={transitionDuration}>
      {(transitionState) => (
        <SlideDownModal
          isOpen={isOpen}
          distanceFromTop={`${74 + bannerHeight}px`}
          transitionDuration={`${transitionDuration}ms`}
        >
          <ModalContainer>
            <ContentContainer transitionState={transitionState}>
              {activeNavGroup === NAV_GROUP_PRODUCTS && (
                <ProductsModalDesktop />
              )}
              {activeNavGroup === NAV_GROUP_SKINCARE && (
                <Skincare101ModalDesktop />
              )}
              {activeNavGroup === NAV_GROUP_SERVICES && (
                <ServicesModalDesktop module={'nav'} />
              )}
            </ContentContainer>
          </ModalContainer>
        </SlideDownModal>
      )}
    </Transition>
  );
};
