import React from 'react';

import { NAV_CHIA_PRODUCT_CATEGORIES_CONTENT } from '../../content';
import { ProductCategory } from './components';
import { NestedLinksContainer } from './ProductLinks.style';

interface ProductLinksProps {
  isOpen?: boolean;
  setIsModalOpen?: (value: boolean) => void;
  isMobile?: boolean;
}

export const ProductLinks = ({
  isOpen = true,
  setIsModalOpen,
  isMobile = false,
}: ProductLinksProps) => {
  return (
    <NestedLinksContainer>
      {NAV_CHIA_PRODUCT_CATEGORIES_CONTENT.map((category) => (
        <ProductCategory
          key={category.category}
          content={category}
          isOpen={isOpen}
          setIsModalOpen={setIsModalOpen}
          isMobile={isMobile}
        />
      ))}
    </NestedLinksContainer>
  );
};
